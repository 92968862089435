import React, { createContext, useContext } from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

const ReCaptchaContext = createContext()

export const ReCaptchaProvider = ({ children }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LcFz7gpAAAAAAqlFmVkD10zt67rvbnKqXHu9Jv0"
      language="pl"
      container={{
        element: "google-recaptcha",
      }}
    >
      {children}
      <div id="google-recaptcha" />
    </GoogleReCaptchaProvider>
  )
}

const useReCaptcha = () => useContext(ReCaptchaContext)

export { useReCaptcha }
export default ReCaptchaProvider
